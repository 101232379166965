import React, {useEffect, useRef, useState} from 'react';
import LoginErrors from "../LoginErrors";
import {appConfig} from "../appconfig";
import {authResponse} from "../SignInRestLib";
import {AuthException} from "../AuthException";
import ResendOTP from "./ResendOTP";
import postRequest from "../restclient";
import GetPhoneNumber from "./GetPhoneNumber";

const axios = require('axios').default;

type MyProps = { config: appConfig, authResponse: authResponse, identifier: string, phoneNumber:string};

export default function GetOTPForMFARegistration(props: MyProps) {
    const [responseState, setResponseState] = useState(props.authResponse);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [code, setCode] = useState<string>('');
    const [onOTPLaunch, setOnOTPLaunch] = useState(false);
    const [error, setError] = useState(0);
    const [onGoBack, setOnGoback] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(0);

        let callbackJson = JSON.parse(props.authResponse.callbackJson);

        for (let index in callbackJson["callbacks"]) {
            if (callbackJson["callbacks"][index].type == "PasswordCallback") {
                callbackJson["callbacks"][index].input[0].value = code;
                break;
            }
        }

        const mfaRequest = {
            mfaCallBackJSON: JSON.stringify(callbackJson)
        };

        axios.post("/login-redirect/services/mfa", mfaRequest,
            {
                params: {
                    goto: props.config.gotoUrl,
                    identifier: props.identifier
                }
            })
            .then(response => {
                const json = response.data as authResponse;
                if (json.success) {
                        window.location.replace(json.url);
                } else {
                    setResponseState(json);
                    if (json.error === AuthException.AUTH_CHANGE_STAGE) {
                        setError(AuthException.AUTH_ERROR_MFA_UNAUTHORIZED);
                    } else if (json.error === AuthException.AUTH_ERROR_MFA_MULTIPLE_FAILURE) {
                        setError(AuthException.AUTH_ERROR_MFA_MULTIPLE_FAILURE_LOGIN_AGAIN);
                    } else if (json.error === AuthException.AUTH_ERROR_MFA_TIMEOUT) {
                        setError(AuthException.SESSION_TIMED_OUT);
                    } else {
                        setError(json.error);
                    }
                }
            });

    }

    const onCodeUpdate = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setCode(e.target.value);

            if(e.target.value.length === 6 ){
                setSubmitEnabled(true);
            }
            else {
                setSubmitEnabled(false);
            }
        }
    };

    function resendCode() {
        setOnOTPLaunch(true);
    }
    function goBack() {

        setError(0);

        let callbackJson = JSON.parse(props.authResponse.callbackJson);

        for (let index in callbackJson["callbacks"]) {
            if (callbackJson["callbacks"][index].type == "ConfirmationCallback") {
                callbackJson["callbacks"][index].input[0].value = 3;
                break;
            }
        }

        const mfaRequest = {
            mfaCallBackJSON: JSON.stringify(callbackJson)
        };

        axios.post("/login-redirect/services/mfa", mfaRequest,
            {
                params: {
                    goto: props.config.gotoUrl,
                    identifier: props.identifier
                }
            }).then(response => {

                const json = response.data as authResponse;
                setResponseState(json);

                if (json.success) {
                    //Not expected. Do nothing.
                    console.log("Success returned. Not Expected");
                } else if (json.error === AuthException.PHONE_NUMBER_REQUIRED) {
                    setOnGoback(true);
                } else if (json.error === AuthException.AUTH_ERROR_MFA_TIMEOUT) {
                    setError(AuthException.SESSION_TIMED_OUT);
                } else {
                    setError(AuthException.AUTH_ERROR_OTHER);
                }
            })
            .catch(reason => {
                setError(AuthException.AUTH_ERROR_OTHER);
            });

    }

    if(error === AuthException.SESSION_TIMED_OUT){
        window.setTimeout(function () {
            window.location.replace(props.config.gotoUrl);
        }, 5000);
    }

    if (onOTPLaunch) {return (<ResendOTP config={props.config} authResponse={responseState} identifier={props.identifier}
                                         password={null} history={[]} mfaRegis = {true} phone={props.phoneNumber}/>);
    }

    if (onGoBack) {return <GetPhoneNumber config={props.config} authResponse={responseState} identifier={props.identifier}
                                          />
    }

    let nextButtonClasses = "btn btn-block pincai-btn-primary";
    nextButtonClasses += submitEnabled ? "" : " disabled";

    const inputStyle = error ? "is-invalid" : "";

    return (
            <div className={"form-signin fs-app-container signin signin--sm"}>
                <h4>2-Step Verification</h4>
                <br/>
                <form className={"form-group"} noValidate={true} autoComplete="off">
                    {responseState !== null && <div>
                        <h4 className={"twofactor-text"}>{props.phoneNumber}</h4><br/>
                        <h4 className={"twofactor-text"}>Verify Code</h4>
                        <p></p>
                        <input type="otp" id="code" name="code" value={code} width={6}
                               pattern="[0-9]*" inputMode="numeric" autoComplete="one-time-code"
                               autoFocus onChange={onCodeUpdate} tabIndex={1} required={true}
                               className={"form-control " + inputStyle}
                               placeholder="Enter code" title="your verification code"/>

                        <LoginErrors error={error}/>
                        <br/>
                        <h4 className={"twofactor-text-hint"}>
                            Please enter the security code sent to your device.
                        </h4>
                        <div className="nav-container-next">
                                <input type="submit" value="Verify" tabIndex={2} disabled={!submitEnabled}
                                       className={nextButtonClasses}
                                       onClick={(e) => handleSubmit(e)}/>

                        </div>
                        <p></p>
                        {error !== AuthException.SESSION_TIMED_OUT ?
                            <h4 className={"twofactor-text-links"}>Didn't get a code? <a
                                className="twofactor-text-links alert-link pull-xs-left zeroBottom extraTopMargin" href="#"
                                onClick={resendCode}>Resend</a></h4>
                            :
                            <h4></h4>
                        }
                        {error !== AuthException.SESSION_TIMED_OUT ?
                            <h4 className={"twofactor-text-links-back"}><a href="#"
                                onClick={goBack}>Go back?</a></h4>
                            :
                            <h4></h4>
                        }
                    </div>
                    }
                </form>
            </div>
    );

}